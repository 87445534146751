:root {
  --text-dark: #3f3f3f;
  --cancel-btn-drawer : #E2DAD6;
  --text-light: #6d6d6d;
  --text-highlight: #BBD67C;
  --primary-400: #00867E;
  --primary-500: #00a79d;
  --primary-700: #4BC1BA;
  --secondary: #00a79c36;
  --secondary-btn: #ebebeb;
  --disabled: #0000001f;
  --error: #B00020;
  --disabled-color: #00000042;
  --picker-200: #eaeaea;
  --picker-300: #F0F0F0;
  --picker-400: #ffffff;
  --picker-500: #ffffff;
  --filter-area-100: #F3F3F3;
  --support-feedback: #ffffff;
  --support-feedback-input-border: #E6E6E6;
}

/* Dark Theme */
[data-theme="dark"] {
  --text-dark: #ffffff;
  --text-light: #b3b3b3;
  --text-highlight: #4A581C;
  --primary-400: #00a79d;
  --primary-500: #00867E;
  --primary-700: #076761;
  --secondary: #188b8436;
  --secondary-btn: #232628;
  --disabled: #ffffff1f;
  --error: #FF4747;
  --disabled-color: #ffffff42;
  --picker-200: #242728;
  --picker-300: #202325;
  --picker-400: #181A1B;
  --picker-500: #232526;
  --filter-area-100: #1F2123;
  /* --support-feedback: #191B1C; */
  --support-feedback: #123A37;
  --support-feedback-input-border: #b3b3b354;
}

* {
  box-sizing: border-box;
}

.dashboard-box {
  padding-inline: 15px;
}

.job-background {
  background-color: var(--picker-400) !important
}

body {
  background-color: var(--picker-400) !important;
  margin: 0;
  padding: 0;
}

.App {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: var(--disabled-color);
  border-radius: 5px;
}

.text-color {
  color: var(--text-dark) !important;
}

.call-center-booking-icon {
  color: var(--primary-400) !important;
}

.dialer {
  background-color: var(--primary-500);
}

[data-theme="dark"] .dialer {
  background: linear-gradient(120deg, rgba(0, 120, 114, 0.658) 0%, rgba(45, 79, 77, 1) 50%, rgba(0, 111, 104, 1) 100%);
  /* background: linear-gradient(90deg, rgba(44, 174, 72, 0.15) 0.25%, rgba(90, 83, 58, 0.13) 99.81%); */
}

.top-bar {
  display: flex;
  height: 45px;
  background-color: var(--picker-400);
  justify-content: space-between;
  padding-inline: 20px;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.09) 0px 2px 8px 0px !important;
  /* margin-bottom: 15px; */
}

.brand-img {
  height: 35px;
}

.top-bar-right {
  display: flex;
  align-items: center;
}

.top-bar-right p {
  margin-right: 10px;
  font-weight: 500;
  font-size: 13px;
  color: var(--text-dark);
}

.top-menu {
  display: flex;
  padding-inline: 20px;
  padding-block: 12px;
  background-color: var(--primary-500);
  justify-content: space-between;
}

.top-menu .top-menu-item {
  margin-right: 10px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 14px;
  border-radius: 4px;
  padding-block: 6px;
  padding-inline: 10px;
}

.top-menu .top-menu-item.active {
  font-weight: 500;
  background-color: var(--primary-700);
}

.sub-menu .sub-menu-item {
  margin-right: 10px;
  color: var(--text-dark);
  text-decoration: none;
  font-size: 13px;
  border-radius: 4px;
  padding-block: 8px;
  padding-inline: 25px;
}

.sub-menu .sub-menu-item.active {
  color: white;
  background-color: var(--primary-500);
  font-weight: 500;
}

.cus-input {
  border: none !important;
  border-color: violet !important;
}

.bg-primary {
  background-color: var(--primary-500) !important;
  color: var(--picker-400) !important;
}

.bg-white {
  background-color: var(--picker-400) !important;
}

.bg-shadow {
  box-shadow: rgba(99, 99, 99, 0.09) 0px 2px 8px 0px !important;
}

.agent-tile {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  border-radius: 0.5em;
  width: 100%;
  height: auto;
  justify-content: space-evenly;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.caller-details h3 {
  margin: 0 !important;
  font-weight: 500;
  font-size: 20px;
  color: var(--text-dark);
}

.caller-details {
  padding: 20px !important;
  background-color: var(--picker-400);
  border-radius: 7px;
}

.agents {
  background-color: var(--picker-500);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 2%;
}

.agents p {
  margin-bottom: 0;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
}

.caller-details small {
  font-size: 12px;
  color: var(--text-light);
  font-weight: 300;
}

.caller-details p {
  margin-bottom: 0;
  margin-top: 5px;
  font-weight: 500;
  font-size: 13px;
}

.btn-primary {
  background-color: var(--primary-500) !important;
  text-transform: capitalize !important;
  box-shadow: rgba(99, 99, 99, 0.09) 0px 2px 8px 0px !important;
  font-size: 13px !important;
  color: white !important;
}

.btn-outlined {
  border-color: var(--primary-500) !important;
  color: var(--primary-500) !important;
}

.button-secondary {
  background-color: var(--secondary) !important;
  text-transform: capitalize !important;
  box-shadow: rgba(99, 99, 99, 0.09) 0px 2px 8px 0px !important;
  font-size: 13px !important;
  color: var(--primary-500) !important;
}

.btn-disable {
  background-color: var(--disabled) !important;
  color: var(--disabled-color) !important;
  text-transform: capitalize !important;
  box-shadow: rgba(99, 99, 99, 0.09) 0px 2px 8px 0px !important;
  font-size: 13px !important;
  cursor: not-allowed !important;
  pointer-events: none;
}

.btn-secondary {
  background-color: var(--secondary-btn) !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  color: var(--primary-500) !important;
  font-weight: 800 !important;
  font-size: 13.5px !important;
}

.disable-btn-drawer {
  background-color: var(--cancel-btn-drawer) !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  color: var(--primary-500) !important;
  font-weight: 800 !important;
  font-size: 13.5px !important;
}

.btn-modal {
  background-color: var(--secondary-btn) !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  color: var(--primary-500) !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.btn-warning {
  background-color: var(--error) !important;
  color: white !important;
  text-transform: capitalize !important;
  box-shadow: rgba(99, 99, 99, 0.09) 0px 2px 8px 0px !important;
  font-size: 13px !important;
  cursor: not-allowed !important;
}

.main h2 {
  font-size: 20px;
  margin-top: 5px !important;
  color: var(--text-dark);
}

.profile-box {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background-color: var(--text-light);
}

.vertical-line {
  width: 1px;
  height: 600px;
  background-color: var(--text-light);

}

.profile-box p {
  font-size: 16px !important;
  font-weight: 500;
  margin-bottom: 7px;
  color: var(--text-dark);
  text-transform: capitalize;
}

.profile-box small {
  font-size: 14px;
  color: var(--text-light);
}

.dialog-title {
  font-size: 17px !important;
  font-weight: 600 !important;
  color: var(--text-dark) !important;
  text-align: left !important;
  margin-bottom: 25px;
  margin-top: 25px;
}

.dialog {
  min-width: 350px !important;
  padding-inline: 20px !important;
  padding-bottom: 20px;
  background-color: var(--support-feedback) !important;
}

.dialog-footer {
  background-color: var(--picker-400);
}

[data-theme="dark"] .dialog {
  background: linear-gradient(to bottom, rgba(18, 58, 55) 5.26%, var(--picker-400) 100%);
}

.dialog-color {
  min-width: 350px !important;
  background-color: var(--support-feedback) !important;
}

[data-theme="dark"] .dialog-color {
  background: linear-gradient(to bottom, rgba(18, 58, 55) 5.26%, var(--picker-400) 100%);
}

.dialog-plain-color {
  min-width: 350px !important;
  background-color: var(--support-feedback) !important;
}

[data-theme="dark"] .dialog-plain-color {
  background: '#123A37';
}

[data-theme="dark"] .dialog-color .dialog-color {
  background-color: "#00A79D20"
}

[data-theme='dark'] .dialog .btn-secondary,
.btn-modal {
  border: var(--primary-500) 1px solid !important;
}

.dialog-map {
  min-width: 100px !important;
  padding-inline: 20px !important;
  padding-bottom: 20px;
}

.dialog p {
  color: var(--text-dark);
  font-weight: 500;
  font-size: 13px !important;
  margin-top: 5px;
}

.dialog small {
  color: var(--text-light);
  font-size: 11px;
}

.login-box {
  height: 350px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.919);
  border-radius: 10px;
  text-align: center;
}

.login-box img {
  width: 230px;
}

.franchise {
  background-color: #00a79c36;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding-block: 15px;
  padding-inline: 25px;
  margin-bottom: 10px;
}

.franchise.warning {
  background-color: rgba(255, 166, 0, 0.193) !important;
}

.franchise.warning p {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
}

.franchise p {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}

.franchise small {
  color: var(--text-light);
  font-weight: 400;
}

.franchise-form {
  border-bottom: var(--primary-700) 1px dotted;
  cursor: pointer;
}

.tabs .tab {
  border-radius: 5px 5px 0px 0px !important;
  font-size: 12px !important;
}

.dashboard h2 {
  margin: 0px !important;
  font-size: 20px;
  color: var(--text-dark);
}

.top-menu-btn {
  color: white;
  margin-right: 10px;
}

.icon-btn {
  background-color: var(--primary-500) !important;
  color: white !important;
}

.icon-btn-disabled {
  background-color: var(--disabled) !important;
  color: white !important;
  pointer-events: none;
}

.MuiLoadingButton-loading.btn-primary span {
  color: white !important;
}

.label.Mui-focused {
  color: var(--primary-500) !important;
}

.no-data {
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: violet;
}

.sub-menu {
  display: flex;
  margin-top: 35px;
  margin-left: 20px;
}

.filters {
  width: 230px;
  background: #f5f5f5;
  padding: 15px;
  margin-right: 20px;
  border-radius: 5px;
  box-sizing: border-box;
}

.filters p {
  margin: 0px;
  margin-bottom: 20px;
  font-weight: bold;
  color: var(--text-dark);
}

.label {
  font-size: 13px;
  color: var(--text-light);
}

.report-bg {
  min-height: 70px;
  height: auto;
  border-radius: 5px;
  padding: 20px;
}

.date-time-panel {
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.st-filter-area {
  background-color: var(--filter-area-100);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-block: 0.5em;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  justify-content: space-between;
  color: var(--text-light);
}

.customer-note-section {
  background-color: var(--filter-area-100);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-block: 0.5em;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  justify-content: space-between;
}

.customer-sections {
  background-color: var(--filter-area-100);
  border-radius: 5px;
  display: flex;
  padding-block: 0.5em;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  justify-content: space-between;
}

.messageBox {
  background-color: var(--text-highlight);
  padding: 5px;
  border-radius: 10px;
  width: 50%;
  margin-left: 1em;
  display: flex;
  align-items: center;
  height: 2em;
}

.messageBoxForWarningMessage {
  background-color: #e66250;
  padding: 5px;
  border-radius: 10px;
  width: 99.5%;
  margin-top: 1em;
  display: flex;
  align-items: center;
  height: 2em;
}

.form-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note-box {
  border: 1px solid rgb(225, 225, 225);
  padding: 10px;
  border-radius: 5px;
}

.note-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.note-item .icon {
  color: #434343;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.note-item .note-item-div {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.note-item .note-item-div p {
  margin: 0;
  font-size: 15px;
  margin-right: 10px;
  font-weight: bold;
}

.note-item .note-item-div .note-text {
  color: rgb(72, 72, 72);
  font-size: 15px;
}

.btn-primary.round {
  border-radius: 50px !important;
  font-size: 12px !important;
  margin-left: 5px;
}

.chat-btn {
  background-color: var(--primary-500) !important;
  color: white !important;
  position: fixed !important;
  bottom: 15px;
  right: 15px;
  z-index: 10000001 !important;
  padding: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.chat .chat-box {
  height: 600px;
  width: 700px;
  position: fixed;
  bottom: 90px;
  right: 15px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  border-radius: 10px;
  z-index: 100001 !important;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
}

.chat-body {
  height: 90%;
  overflow-y: auto !important;
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  width: 40%;
  flex-direction: column-reverse;
}

.chat-flex-box {
  height: 100%;
  display: flex;
  width: 100%;
}

.chat-suggestions {
  width: 60%;
  border-right: 1px solid rgb(10, 10, 10);
  height: 93%;
  margin-right: 10px;
  box-sizing: border-box;
  display: flex;
  overflow-y: auto !important;
  flex-direction: column-reverse;
}

.chat-head {
  display: flex;
  height: 10%;
  justify-content: space-around;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.chat-head p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 13px;
  color: var(--text-dark);
}

.chat-head small {
  font-size: 11px;
  color: var(--text-dark);
}

.chat-head div {
  margin-right: 40px;
}

.chat-item .icon {
  height: 30px;
  width: 30px;
  background-color: #dddddd75;
  color: #00a79d;
  margin-right: 10px;
}

.chat-item .icon svg {
  height: 20px;
}

.chat-item {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
}

.chat-item.out-going {
  justify-content: flex-start;
}

.dialog-zindex {
  z-index: 7 !important;
}

.suggestions {
  background-color: #f0f0f0 !important;
  width: auto;
  padding: 10px 10px 10px 10px;
  color: rgb(43, 43, 43);
  border-radius: 5px;
  max-width: 90%;
  margin-bottom: 10px;
}

.suggestions p {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 20px;
  margin-top: 8px;
  color: #5c5c5c;
}

.suggestions strong {
  font-size: 14px;
}

.suggestions .time-slot {
  margin-top: 5px !important;
  font-size: 12px;
}

.suggestions .time-slot>strong {
  font-size: 11px;
  color: #000000;
}

.table-header {
  font-size: 14px;
  max-height: 69px;
}

.table-cell {
  font-size: 12px;
  max-height: 69px;
}

.pagination-bar {
  margin-top: 25px;
  float: right;
}

.job-step-icon {
  cursor: pointer;
  transition: 250ms;
}

.job-step-icon:hover {
  transform: scale(1.05);
}

.job-section-button:hover {
  transition: 250ms;
  transform: scale(1.01);
}

.time-slot {
  background-color: #00a79d;
  color: white;
  cursor: pointer;
}

.report-data {
  width: 100%;
}

.report-data .data-row {
  display: flex;
}

.report-data .data-row .data-item {
  flex: 1;
  margin-inline: 10px;
  background-color: #f5f5f5;
  height: 80px;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
}

.report-data .data-row .data-item p {
  margin: 0px;
  font-size: 13px;
  color: var(--text-dark);
}

.report-data .data-row .data-item .count {
  font-size: 20px;
  font-weight: bold;
}

.report-data .data-row .data-item:nth-child(1) {
  margin-left: 0px;
}

.report-data .data-row .data-item:nth-child(5) {
  margin-right: 0px;
}

.date-time-panel {
  margin-top: -10px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: flex-end;
}

.score-margins {
  display: flex;
  align-items: center;
  /* justify-content: flex-end;
  margin-top: -20px;
  margin-bottom: 10px; */
}

.score-margins .margin {
  margin: 0 10px;
  display: flex;
  align-items: center;

}

.score-margins .margin p {
  font-size: 14px;
  text-transform: capitalize;
}

.score-margins .margin .box {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  border-radius: 2px;
}

.green {
  background: green;
}

.orange {
  background: orange;
}

.red {
  background: red;
}

/* react date picker classes __________________________________________________ */

.react-datepicker {
  background-color: var(--picker-400) !important;
}

.react-datepicker__day {
  color: var(--text-dark) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--picker-500) !important;
  color: var(--text-dark) !important;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: var(--picker-300) !important;
  color: var(--text-light) !important;
}

.react-datepicker__day:hover {
  background-color: var(--picker-200) !important;
}

.react-datepicker__day--today {
  background-color: var(--picker-200) !important;
  color: var(--text-light) !important;
}

.react-datepicker__day--selected {
  background-color: var(--primary-500) !important;
}

.react-datepicker__day--selected:hover {
  background-color: var(--primary-500) !important;
}

.react-datepicker__day--disabled {
  color: #696767dc !important;
}

.react-datepicker__header {
  background-color: var(--picker-400) !important;
  color: var(--text-dark) !important;
}

.react-datepicker__current-month {
  color: var(--text-dark) !important;
}

.react-datepicker__day-name {
  color: var(--text-dark) !important;
}

.react-datepicker-time__header {
  background-color: var(--picker-400) !important;
  color: var(--text-dark) !important;
}

.react-datepicker__time-list-item--selected {
  background-color: var(--primary-500) !important;
}

.react-datepicker__time-list-item {
  background-color: var(--picker-400);
  color: var(--text-dark) !important;
}

.react-datepicker__time-list-item:hover {
  background-color: var(--picker-200) !important;
}

.react-datepicker__time-list-item--selected:hover {
  background-color: var(--primary-500) !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__time-list-item--disabled {
  color: #696767dc !important;
}

/* ____________________________________________________________________________ */

.notification {
  margin-right: 2px;
}

.ab-call-content-wrapper {
  display: flex;
}

.callback-content-wrapper {
  display: flex;
}

.item-content-text {
  font-size: 12px;
  color: #172B4D;
  font-weight: 300;
  margin-left: 5px;
}

.missed-call-icon {
  font-size: 16px !important;
  margin-top: -2px;
  margin-left: 25px;
}

.calendar-icon {
  font-size: 16px !important;
  margin-top: -2px;
  margin-left: 5px;
}

.notification-datetime {
  display: flex;
  font-size: 12px;
  color: #1A2027;
  text-align: end;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.callback-msg-content {
  display: flex;
}

.callback-msg-content-header {
  font-size: 12px;
  color: #172B4D;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 25px;
}

.callback-msg-content-text {
  font-size: 12px;
  color: #172B4D;
  font-weight: 300;
  margin-top: 10px;
  margin-left: 10px;
}

.no-notifications {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  color: #d3d6db;
  margin-top: 100px;
}

.card-crm {
  background-color: var(--picker-400);
}

/* ___________________SPAM MODAL________________________________ */

.callback-status-spam {
  margin-top: 12px;
}

.callback-column {
  display: flex;
}

.spam-box {
  margin-left: 5px;
}

.spam-dialog {
  min-width: 550px !important;
}

.spam-dialog-title {
  margin-bottom: 10px !important;
}

.spam-label {
  font-size: 15px;
  color: var(--text-dark);
  font-weight: 500;
}

.spam-data {
  font-size: 18px;
  color: var(--text-dark);
  font-weight: 300;
  margin-top: 10px;
}

.spam-msg-content {
  margin-top: 20px;
  overflow-y: auto;
  min-height: 120px;
  max-height: 120px;
  border: 1px solid var(--text-dark);
}

.spam-msg-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.spam-msg-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--text-dark);
}

.spam-msg-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--text-dark);
}

.spam-msg-content::-webkit-scrollbar-thumb:hover {
  background: var(--text-dark);
}

.spam-msg-content::-webkit-scrollbar-thumb:active {
  background: var(--text-dark);
}

.spam-msg-item {
  margin: 5px 5px 5px 10px;
  font-size: 18px;
  color: var(--text-dark);
  font-weight: 300;
}

.spam-msg-item-time {
  font-size: 10px;
  color: var(--text-dark);
  font-weight: 100;
  margin: 0px 0px 10px 10px;
}

/* Caller Data Selection Panel __________________________________________________ */

.caller-data-container {
  background-color: var(--primary-500);
}

.caller-data-panel {
  background-color: var(--primary-500);
  display: table;
  height: 88vh;
  width: 100%;
}

.form-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.call-log-btn-container {
  padding: 20px;
}

.caller-data-form {
  background-color: hsla(0, 0%, 100%, .919);
  border-radius: 3px;
  padding: 20px;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.caller-data-form h2 {
  color: var(--text-dark);
  font-size: 20px;
  margin: 0 !important;
}

.caller-data-input-wrapper {
  padding-top: 30px;
}

.caller-data-form-header {
  display: flex;
}

.caller-request-type-chip {
  margin-left: auto;
}

.caller-data-panel-footer {
  text-align: right;
  padding-top: 30px;
  padding-bottom: 20px;
}

.caller-type {
  margin-top: 5px;
  display: flex;
}

.caller-type-icon {
  margin-right: 5px;
}

.caller-type-text {
  margin-top: 2px;
}

.user-dialog {
  min-width: 550px !important;
}

.success-label {
  color: var(--primary-500) !important;
}

/*-_______________________________________________________________ */

/* ______________________________________________________________________________ */

/*______________________JIRA Supporter___________________________________________*/

#JIRASUPPOTER-V1-SUPPORT_BUTTON {
  background-color: var(--picker-400);
  border: var(--primary-500) 2px solid;
  color: var(--primary-400) !important;
}

#JIRASUPPOTER-V1-SUPPORT_MODAL-SUBMIT_BUTTON {
  background-color: var(--support-feedback);
  border: #00a79d 2px solid;
  color: #00a79d;
}

#JIRASUPPOTER-V1-SUPPORT_MODAL h3 {
  color: var(--text-dark) !important;
}

#JIRASUPPOTER-V1-SUPPORT_MODAL-CONTENT {
  background-color: var(--support-feedback);
}

.JIRASUPPOTER-V1-SUPPORT_MODAL-INPUT {
  background-color: var(--support-feedback) !important;
  color: var(--text-dark) !important;
  border: var(--support-feedback-input-border) 1px solid !important;
}

#JIRASUPPOTER-V1-SUPPORT_MODAL-CLOSE {
  color: var(--text-light) !important;
}

#JIRASUPPOTER-V1-SUPPORT_MODAL-CLOSE:hover {
  color: var(--text-dark) !important;
}

#JIRASUPPOTER-V1-SUPPORT_MODAL-IMAGE {
  border: var(--support-feedback-input-border) 1px solid !important;
}

/*-______________________________________________________________________________*/

input::-webkit-inner-spin-button {
  /* To hide number type field up and down arrows (franchiseId firld on franchise model) */
  -webkit-appearance: none;
  margin: 0;
}

.apexcharts-legend-text {
  color: var(--text-dark) !important;
}

.apexcharts-datalabels-group .apexcharts-text {
  color: '#ffffff' !important;
}