@import url("../../../../App.css");


.faq-icon-btn {
    position: absolute !important;
    right: 10px !important;
    top: 10px !important;
}

.close-icon {
    height: 15px !important;
    width: 15px !important;
}

.faq-modal-title {
    margin-bottom: 5px !important;
}

.name-tag {
    font-size: 13px;
    display: flex;
    padding-top: 5px;
}

.form-container {
    margin-top: 40px
}

.form-field-wrapper {
    margin-top: 20px
}

.checkbox-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    width: 100%;
    height: 33px;
}

.loading-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.tag-title {
    font-size: 15px !important;
}