@import url("../../../App.css");

.login-page {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-content {
    width: 70%;
    height: 50%;
    z-index: 10000001;
    display: flex;
    justify-content: space-between;
}

.login-content .login-col {
    height: 100%;
}

.login-content .login-col:nth-child(1) {
    width: 45%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.login-content .login-col:nth-child(1) img {
    width: 65%;
}

.login-content .login-col:nth-child(2) {
    background-color: #ffffff;
    width: 52%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.25px solid var(--primary-500) !important;
}

.login-content .login-col:nth-child(2) h1 {
    color: #292929
}

.login-content .login-col:nth-child(2) p {
    color: var(--text-light);
}

.login-content .login-col:nth-child(2) ul {
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
}

.login-content .login-col:nth-child(2) ul li {
    font-size: 14px;
    color: var(--text-light);
    margin-bottom: 10px;
}

.btn-google {
    text-transform: none !important;
    width: 200px !important;
    background-color: rgb(255, 255, 255) !important;
    padding-inline: 10px !important;
    padding-block: 10px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    color: #3f3f3f !important;
    margin-top: 40px !important;
    border: 0.25px solid var(--primary-500) !important;
}

.btn-google img {
    height: 25px !important;
    width: 25px !important;
    margin-right: 10px;
}

.login-background {
    height: 100%;
    width: 70%;
    position: fixed;
    right: 0;
    overflow: hidden;
}