@import url("../../../../App.css");

.no-data-available-score {
    margin-top: 60%;
    margin-left: auto;
    margin-right: auto;
}

.no-data-available-chart {
    margin-top: 10%;
    margin-left: 7%;
}

.no-data-available-status-codes {
    margin-top: 60%;
    margin-left: 15%;
    margin-right: auto;
}

.upper-section {
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: auto;
}

.gray-line {
    height: 35px;
    width: 1px;
    background-color: gray !important;
    margin: 15px 15px;
}

.my-score-container {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.date-picker-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}

.loading {
    height: 70vh;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-code-section-loading {
    height: 62vh;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.score-card-loading {
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agent-score-section {
    height: 67vh;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 20px !important;
    background-color: white;
    border-radius: 7px;
}

.chart-section {
    padding: 20px !important;
    background-color: white;
    border-radius: 7px;
    height: 67vh;
}

.status-code-section {
    height: 67vh;
    overflow: auto;
    padding: 20px !important;
    background-color: white;
    border-radius: 7px;
}

.status-code-section p {
    margin-bottom: 0;
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
}

.agent-score-section p {
    margin-bottom: 0;
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
}

.title {
    margin: 0 !important;
    font-weight: 500;
    font-size: 20px;
    color: var(--text-dark);
}

.chart-section p {
    margin-bottom: 0;
    margin-top: 5px;
    font-weight: 500;
    font-size: 13px;
}

.top-agents-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: auto;
}

.other-agents-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: auto;
    margin-top: 1em;
}

.status-code-container-of-break-codes {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 5%;
    border-radius: 5%;
    background-color: var(--picker-300) !important;
}

.agent-monitoring-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: auto;
    background-color: var(--picker-500) !important;
}

.agents-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    color: gray !important;
}

.agents-section {
    display: flex;
    flex-direction: column;
    padding: 2%;
    gap: 1em;
    height: 95% !important;
}

.status-code-container-of-offline-codes {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 5%;
    border-radius: 5%;
    background-color: var(--picker-300) !important;
}

.working-days-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    margin-right: 25px;
    padding-right: 10px;
}

.status-code-accordation {
    border-left-style: solid;
    border-left-width: 10px
}

.status-code-title {
    font-size: 15px;
    font-weight: 500;
}

.top-agent-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
    border-radius: 0.5em;
    width: 100%;
    background-color: #D5F5E3 !important;
    justify-content: space-evenly;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.time-card {
    color: white !important;
    padding-bottom: 3px;
    padding-left: 3px;
    height: 22px;
    padding-right: 3px;
    background-color: #00a79d;
    border-radius: 0.2em;
}

.time-card p {
    margin: 3px 0px 0px;
    font-size: 12px;
    font-weight: 500;
}

.working-days-number {
    color: white !important;
    display: flex;
    flex-direction: row;
    height: 30px !important;
    margin-left: auto;
    gap: 0.2em;
    min-width: 30px;
    padding: 0.2em;
    background-color: #00a79d;
    border-radius: 0.2em;
}

.working-days-number-wrap {
    margin-top: 2px;
    padding-left: 0.1em;
}

.agent-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
    border-radius: 0.5em;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.score-data {
    width: 100%;
}

.score-data .data-row {
    display: flex;
    margin-left: 1%;
    margin-top: 1%;
}

.score-data .data-row .data-item {
    flex: 1;
    margin-inline: 10px;
    background-color: #f5f5f5;
    height: 80px;
    border-radius: 5px;
    padding: 15px;
    box-sizing: border-box;
}

.score-data .data-row .data-item p {
    margin: 0px;
    font-size: 13px;
    color: var(--text-dark);
}

.score-data .data-row .data-item .count {
    font-size: 20px;
    font-weight: bold;
}

.score-data .data-row .data-item:nth-child(1) {
    margin-left: 0px;
}

.score-data .data-row .data-item:nth-child(5) {
    margin-right: 0px;
}

.status-code-item-container {
    display: flex;
    flex-direction: row;
}

.score-card-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 10px;
}

.chart-section-container {
    display: flex;
    flex-direction: column;
    gap: 1em
}

.image-style {
    height: 55px;
    border-radius: 50%;
}

.name-style {
    font-weight: 500;
    margin-left: 20px;
    color: var(--text-dark);
}

.score-title {
    margin-right: 10px;
    margin-top: 6px;
}

.top-bar-right-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.agent-statistics-title-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.agent-search-container {
    display: flex;
    flex-direction: row;
}

.agent-section-loading-container {
    display: flex;
    flex-direction: column;
}

.agent-timer {
    background-color: var(--primary-500);
    color: white;
    padding: 0.3em;
    border-radius: 0.2em;
}

.monitor-btn {
    background-color: var(--primary-500) !important;
    color: white !important;
}

.monitor-btn-disabled {
    background-color: var(--disabled-color) !important;
    color: white !important;
}

@keyframes bounce {
    to {
        transform: scale(1.1);
    }
}

video::-webkit-media-controls-panel {
    display: none !important;
}

.agent-logs-upper-section {
    min-height: 10% !important;
    padding: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
}

.agent-logs-lower-section {
    height: 67vh !important;
    display: flex;
    flex-direction: column;
    margin-top: 1%;
    border-radius: 5px;
    gap: 10px;
    padding: 20px;
}

.agent-log-title-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
}

.logs-icon {
    width: 30px;
    height: 30px;
    margin-left: 2px;
}

.logs-card {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logs-tag {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 20px;
    height: 30px;
    margin-left: 30%;
    margin-right: auto;
    background-color: var(--picker-400);
}

.logs-tag-icon {
    width: 30px;
    margin-left: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 50px;
}

.logs-paragraph {
    font-size: 18px;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 10px;
}