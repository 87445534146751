@import url("../../../App.css");

.gm-style-iw {
    background-color: var(--picker-400) !important;
    padding: 10px 12px 8px 10px !important;
}

.gm-style-iw-d {
    overflow: hidden !important;
}

.gm-style-iw-tc::after {
    background: var(--picker-400) !important;
}

.custom-info-window::-webkit-scrollbar {
    width: 10px;
}

.custom-info-window::-webkit-scrollbar-thumb {
    background: var(--disabled-color);
}

.custom-info-window::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.gm-ui-hover-effect>span {
    background-color: var(--text-dark) !important;
}

/* .gm-ui-hover-effect {
    opacity: 1 !important;
} */