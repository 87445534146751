@import url("../../../../App.css");

.faq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.faq-title {
    display: flex;
    align-items: flex-end;
}

.tab-btn {
    width: 100px !important;
}

.accordian-wrapper {
    margin-top: 30px;
    height: 600px;
    overflow-y: scroll;
    padding-top: 10px;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: 8%;
    margin-right: 8%;
}

.accordian-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.accordian-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.accordian-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.accordian-wrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
}

.accordian-wrapper::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.5);
}

.accordian-header {
    padding-top: 8px !important;
    padding-bottom: 10px !important;

}

.accordian-item {
    margin-bottom: 15px;
}

.filter-container {
    margin-left: auto;
    margin-right: 8%;
    margin-top: 20px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: 3px 2px 2px 2px rgb(0 0 0 / 5%), 0px 1px 2px 1px rgb(0 0 0 / 12%) !important
}

.no-record-div {
    height: 600px;
    overflow-y: hidden;
}

.question {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.action-bar {
    text-align: right;
    padding: 0px 20px 15px 20px;
}

.action-icon {
    margin: 5px;
    cursor: pointer;
}

.loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 650px;
}

.search-field {
    width: 300px;
    margin-right: 15px !important;
}

.add-icon {
    margin-left: 10px !important;
    padding: 10px !important;
}

.faq-btn-disable {
    background-color: var(--disabled) !important;
    color: var(--disabled-color) !important;
    text-transform: capitalize !important;
    box-shadow: rgba(99, 99, 99, 0.09) 0px 2px 8px 0px !important;
    font-size: 13px !important;
    cursor: not-allowed !important;
}

.question-wrapper {
    width: 100%;
}

.franchise-name {
    font-size: 13px;
    display: inline-flex;
}

.franchise-icon {
    font-size: 20px !important;
    margin-top: -2px !important;
    margin-right: 2px !important;
}