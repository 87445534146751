@import url("../../../App.css");

.agent-assist-box {
    color: var(--text-dark) !important;
    width: 700px;
    height: 83%;
    z-index: 10000001 !important;
    padding: 15px !important;
}

.right-dock-toggle-btn {
    border: 2px solid var(--primary-500) !important;
    color: black !important;
    position: fixed !important;
    top: 25%;
    right: 0%;
    z-index: 10000001 !important;
    padding-right: 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    writing-mode: vertical-lr;
    text-align: center;
    height: 500px;
    width: 15px;
    transition: 0.5s;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.65);
}

.right-dock-toggle-btn:hover {
    width: 66px;
    border: 2px solid var(--primary-500) !important;
    box-shadow: none;
    cursor: pointer;
}

.right-dock {
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
}

.right-dock li {
    border-radius: 8px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    color: darkgreen;
    z-index: 10000002 !important;
    margin-bottom: 10px;
}

.right-dock li:hover {
    transform: scale(1.5);
}

.dock-drawer {
    display: inline;
}

.aa-head {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: var(--primary-500);
    display: flex;
    justify-content: space-around;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.aa-head p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 13px;
    color: var(--text-dark);
}

.aa-head small {
    font-size: 11px;
    color: white;
    font-weight: bold;
}

.aa-head div {
    margin-right: 40px;
}

.aa-connect-img {
    border: 1.8px solid black;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 2px;
}

.aa-body {
    height: 100%;
    display: flex;
    width: 100%;
}

.faq-suggestions {
    border-right: 1px solid var(--text-light);
    height: 93%;
    margin-right: 10px;
    box-sizing: border-box;
    display: flex;
    overflow-y: auto !important;
    flex-direction: column-reverse;
    transition: 1s;
}

.faq-suggestions-half {
    width: 60%;
}

.faq-suggestions-full {
    width: 100%;
    border-right: none;
}

.suggestions-body {
    background-color: #f0f0f0 !important;
    width: auto;
    padding: 10px 10px 10px 10px;
    color: rgb(43, 43, 43);
    border-radius: 5px;
    max-width: 90%;
    margin-bottom: 10px;
}

.suggestions-body p {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 20px;
    margin-top: 8px;
    color: #5c5c5c;
}

.suggestions-body strong {
    font-size: 14px;
}

.suggestions-body .time-slot {
    margin-top: 5px !important;
    font-size: 12px;
}

.suggestions-body .time-slot>strong {
    font-size: 11px;
    color: #000000;
}

.transcript-body {
    height: 90%;
    overflow-y: auto !important;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    width: 40%;
    flex-direction: column-reverse;
}


.transcript-item {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
}

.transcript-item.out-going {
    justify-content: flex-start;
}

.transcript-item .icon {
    height: 30px;
    width: 30px;
    background-color: #dddddd75;
    color: #00a79d;
    margin-right: 10px;
}

.transcript-item .icon svg {
    height: 20px;
}

.error-view {
    margin-top: 30%;
    height: 78%;
}

.aa-chat-input {
    /* background-color: #FFFFFF; */
    margin-left: 10px;
    position: relative;
    bottom: 20px;
    width: 95%;
    height: auto;
    display: flex;
    align-items: flex-end;
}

.chat-incoming {
    background-color: rgb(192, 247, 192) !important;
    width: auto;
    padding: 10px 10px 10px 10px;
    color: rgb(43, 43, 43);
    border-radius: 5px;
    text-align: right;
    max-width: 100%;
    display: flex;
    justify-content: flex-end;
    border-radius: 0px 40px 40px 0px;
}

.chat-incoming p {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 0px;
    line-height: 20px;
}

.chat-incoming small {
    font-size: 11px;
}

.chat-incoming strong {
    color: #000000;
    font-weight: 700;
}

.chat-incoming svg {
    height: 13px;
}

.chat-outgoing {
    background-color: #f0f0f0 !important;
    width: auto;
    padding: 10px 10px 10px 10px;
    color: rgb(43, 43, 43);
    border-radius: 5px;
    text-align: left;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    border-radius: 40px 0px 0px 40px;
}

.chat-outgoing p {
    font-size: 13px;
    /* font-weight: bold !important; */
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 0px;
    line-height: 20px;
    text-align: left;
    word-wrap: break-word;
}

.chat-outgoing strong {
    font-size: 14px;
}

.chat-outgoing svg {
    height: 13px;
}

.chat-outgoing strong {
    color: #000000;
    font-weight: 700;
}

.chat-outgoing small {
    font-size: 11px;
    float: left;
}

.chat-lead {
    border-radius: 5px;
    border: 1px solid #dadada;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}

.chat-lead h4 {
    margin-top: 0px;
    font-size: 15px;
    margin-bottom: 12px;
    color: var(--text-dark);
    font-weight: 700;
}

.chat-lead p {
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 13px;
    color: var(--text-dark);
}

.chat-lead small {
    font-size: 11px;
    color: var(--text-dark);
}

.chat-lead small svg {
    height: 14px;
    width: 14px;
    margin-right: 8px;
}

.aa-realtime-transcript-box {
    background-color: lightblue;
    margin-top: 2px;
    padding: 2px;
    border-radius: 20px 0px 0px 20px;
    display: flex;
    align-items: center;
    width: auto;
}

.faq-question {
    color: #000000;
    padding: 2px;

}

.chat-manual {
    background-color: lightblue !important;
    width: auto;
    padding: 10px 10px 10px 10px;
    color: rgb(43, 43, 43);
    border-radius: 5px;
    text-align: left;
    max-width: 100%;
    display: flex;
    justify-content: flex-end;
    border-radius: 10px 10px 10px 10px;
}